import axios from "axios";
import { createContext, useEffect, useState } from "react";

const AppContext = createContext();

export const AppContextProvider = (props) => {
  const { children } = props;
  let [Projects, setProjects] = useState([]);
  let [lang, setLang] = useState("ar");
  const changeLanguage = () => {
    if (lang === "ar") {
      document.documentElement.dir = "rtl";
      setLang("en");
    } else {
      document.documentElement.dir = "ltr";
      setLang("ar");
    }
  };

    useEffect(() => {
      axios.get("https://reda.daimooma.com/projects").then((resp) => {
        setProjects(resp.data.projects);
      });
    }, []);

  return (
    <AppContext.Provider value={{ Projects, lang, changeLanguage }}>
      {children}
    </AppContext.Provider>
  );
};
export default AppContext;
