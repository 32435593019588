import React, { useContext } from "react";
import { FiPhone, FiMail } from "../assets/icons/icons"; // Assuming these icons are available
import AppContext from "../context/AppContext";

export default function GetInTuch({ title }) {
  const { lang } = useContext(AppContext);
  return (
    <div className="container relative md:mt-24 mt-16">
      <div className="grid grid-cols-1 text-center">
        {title === true ? (
          <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
            {lang === "ar" ? "تواصل معنا" : "Contact Us"}
          </h6>
        ) : (
          ""
        )}
        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
          {lang === "en"
            ? "هل لديك سؤال؟ ابق على تواصل معنا"
            : "Have a question? Stay in touch with us"}
        </h3>

        <p className="text-slate-400 max-w-xl mx-auto">
          {lang === "en"
            ? "نحن هنا لمساعدتك! إذا كان لديك أي استفسار أو ترغب في معرفة المزيد عن خدماتنا، فلا تتردد في الاتصال بنا. فريقنا جاهز للرد على جميع أسئلتك وتقديم الدعم الذي تحتاجه. يمكنك التواصل معنا عبر الهاتف، واتساب ، أو زيارة مكاتبنا في أي وقت خلال ساعات العمل. نسعد بخدمتك ونتطلع إلى سماعك قريبًا."
            : "We are here to help! If you have any questions or would like to know more about our services, please do not hesitate to contact us. Our team is ready to answer all your questions and provide the support you need. You can contact us by phone, WhatsApp, or visit our offices anytime during business hours. We are happy to serve you and look forward to hearing from you soon."}
        </p>

        {/* Contact Options */}
        <div className="mt-6 flex space-y-4 items-center justify-center flex-wrap gap-2">
          {/* Phone Numbers */}
          <div className="flex flex-col space-y-2">
            <a
              href="tel:+201222180497"
              className="py-2 px-5 inline-flex font-semibold tracking-wide border items-center duration-500 text-base bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
            >
              <FiPhone className="me-2 text-lg" /> 00201222180497
            </a>
          </div>

          {/* Email Addresses */}
          <div className="flex flex-wrap gap-8 space-y-2">
            <a
              href="mailto:contact@alredacontracting.com"
              className="py-2 px-5 inline-flex font-semibold tracking-wide border items-center duration-500 text-base bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
            >
              <FiMail className="me-2 text-lg" /> contact@alredacontracting.com
            </a>
            <a
              href="mailto:info@alredacontracting.com"
              className="py-2 px-5 inline-flex font-semibold tracking-wide border items-center duration-500 text-base bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
            >
              <FiMail className="me-2 text-lg" /> info@alredacontracting.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
