import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./assets/libs/@mdi/font/css/materialdesignicons.min.css";

import IndexRealEstate from "./pages/index/indexRealEstate";
import Switch from "./component/Switch";
import { AppContextProvider } from "./context/AppContext";
import ShopGridTwo from "./pages/ecommerce/shopGridTwo";
import PropertyDetails from "./pages/real-estate/propertyDetail";
import IndexClassicBusiness from "./pages/index/index-classic-business";
import Blog from "./component/blog";

export default function App() {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Switch />
        <Routes>
          <Route path="/" element={<IndexClassicBusiness />} />
          <Route path="/projects/:category" element={<Blog />} />
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  );
}
