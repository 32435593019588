import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../component/Navbar/navbar";
import Footer from "../component/Footer/footer";
import AppContext from "../context/AppContext";
import languages from "../db.json";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox"; // Import the Lightbox component

export default function Blog(props) {
  const [data, setData] = useState([]);
  const { lang, Projects } = useContext(AppContext);
  const { category } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  useEffect(() => {
    const filteredData = Projects.filter((item) => item.category === category);
    setData([...filteredData]);
  }, [category, Projects]);

  const transalte = {
    general: "work1",
    marine: "work2",
    specialized: "work3",
    road: "work4",
  };

  const handleImageClick = (imageUrl) => {
    setCurrentImage(imageUrl);
    setIsOpen(true);
  };

  return (
    <>
      <Navbar />
      <div className={"min-h-screen p-8"}>
        <div className="grid grid-cols-1 pb-8 text-center text-orange-800 bg-black rounded-md mt-20">
          <h3 className=" md:text-4xl md:leading-normal text-2xl leading-normal font-semibold mt-4">
            {languages[lang][transalte[category]]}
          </h3>
        </div>
        {/* Categories */}
        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px] p-8">
          {data.map((item, index) => (
            <div
              key={index}
              className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden wow animate__animated animate__fadeInUp"
              data-wow-delay={1}
            >
              <img
                src={item.image}
                alt=""
                style={{ objectFit: "cover", width: "100%", height: "260px", cursor: "pointer" }}
                onClick={() => handleImageClick(item.image)} // Handle image click
              />

              <div className="content p-6">
                <Link className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">
                  {item[`name${lang === "ar" ? "_en" : ""}`]}
                </Link>
                {item.owner_name && (
                  <p className="text-red-800 mt-3">
                    {lang === "en" ? "اسم المالك" : "Owner Name"} :{" "}
                    {item[`owner_name${lang === "ar" ? "_en" : ""}`]}
                  </p>
                )}
                {item.consult_name && (
                  <p className="text-red-800 mt-3">
                    {lang === "en" ? "اسم الاستشاري" : "Consultant Name"} :{" "}
                    {item[`consult_name${lang === "ar" ? "_en" : ""}`]}
                  </p>
                )}
                {item.period && (
                  <p className="text-red-800 mt-3">
                    {lang === "en" ? "مدة التنفيذ" : "Duration"} :{" "}
                    {item[`period${lang === "ar" ? "_en" : ""}`]}
                  </p>
                )}
                <p className="text-slate-400 mt-3">
                  {item[`description${lang === "ar" ? "_en" : ""}`]}
                </p>
              </div>
            </div>
          ))}
        </div>

        {!data.length && (
          <p className="block text-center text-5xl font-bold w-full">
            {lang === "en" ? "ﻻ يوجد مشاريع هنا الان" : "No projects here yet"}
          </p>
        )}
      </div>
      <Footer />

      {/* Lightbox Component */}
      {isOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </>
  );
}
