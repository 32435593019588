import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import bg1 from "../../assets/images/redaBg.jpg";
import meeting from "../../assets/images/meeting.avif";
import manContract from "../../assets/images/manContracting.jpg";
import shapeImage from "../../assets/images/shape-image.png";
import aboutImage from "../../assets/images/it/about.jpg";
import * as Icon from "react-feather";
import {
  FiMail,
  FiPhone,
  FiDribbble,
  FaBehance,
  FiLinkedin,
  FiFacebook,
  FiInstagram,
  FiBox,
  FiCamera,
  FiBell,
  FiMonitor,
  PiNotepadBold,
  BsCheckCircle,
  MdKeyboardArrowRight,
} from "../../assets/icons/icons";
import NavbarTwo from "../../component/Navbar/navbarTwo";
import Blog from "../../component/blog";
import UserFeedBack from "../../component/userFeedBack";

import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.css";

import CountUp from "react-countup";
import Footer from "../../component/Footer/footer";
import CookieModal from "../../component/cookieModal";
import Navbar from "../../component/Navbar/navbar";
import spec1 from "../../assets/images/genralContracting.avif";
import spec2 from "../../assets/images/spec2.jpg";
import spec3 from "../../assets/images/spec3.jpg";
import spec4 from "../../assets/images/spec4.jpg";
import ContactOne from "../contact/contactOne";
import GetInTuch from "../../component/getInTuch";
import languages from "../../db.json";
import AppContext from "../../context/AppContext";
export default function IndexClassicBusiness() {
  let [isOpen, setOpen] = useState(false);

  const { lang } = useContext(AppContext);
  return (
    <div className="font-cairo">
      <Navbar navClass="nav-sticky" />

      <section
        className="relative table w-full md:pt-72 md:pb-40 py-36 bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className="container relative mt-10">
          <div className="grid grid-cols-1 ">
            <h1 className="font-bold font-cairo lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-center text-white">
              {languages[lang].welcomeTitle}
              <br />
              {languages[lang].continueTitle}
            </h1>
          </div>
        </div>
      </section>
      <div id="aboutUs"></div>
      <section className="relative overflow-hidden md:py-24 py-16">
        <div className="container relative pb-16">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div className="lg:col-span-5 md:col-span-6">
              <div className="relative">
                <div className="relative flex justify-end">
                  <img
                    src={meeting}
                    className="lg:w-[400px] w-[280px] rounded shadow dark:shadow-gray-700"
                    alt=""
                  />
                  <ModalVideo
                    channel="youtube"
                    autoplay
                    isOpen={isOpen}
                    videoId="S_CGed6E610"
                    onClose={() => setOpen(false)}
                  />
                </div>
                <div className="absolute md:-start-5 start-0 -bottom-16">
                  <img
                    src={manContract}
                    className="lg:w-[280px] w-[200px] border-8 border-white dark:border-slate-900 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="lg:col-span-7 md:col-span-6">
              <div
                className="lg:ms-5"
                style={{
                  clipPath:
                    lang == "en"
                      ? "polygon(25% 0, 100% 0, 100% 100%, 25% 100%, 0% 50%)"
                      : "polygon(75% 0, 0% 0, 0% 100%, 75% 100%, 100% 50%)",
                  background: "linear-gradient(to right, #000, #333)",
                  position: "relative",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  padding: "30px",
                  zIndex: -1,
                  height: "450px",
                }}
              >
                {/* <h6 className="text-indigo-600 text-lg font-bold uppercase mb-2 mt-5">{languages[lang].about1}</h6> */}
                <h3 className="mb-2 mt-5 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-orange-700">
                  {lang == "en" ? "رؤية" : "Our"} <br />
                  {lang == "en" ? "الشركة" : "Vision"}
                </h3>

                <p
                  className={`text-white text-${
                    lang == "en" ? "lg" : "base"
                  } max-w-xl mb-6 w-5/6`}
                >
                  {languages[lang].welcomeDescription}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`container relative md:mt-24 mt-16 md:mb-24 mb-16 bg-gradient-to-${
            lang == "en" ? "r" : "l"
          } from-black to-gray-900 bg-opacity-70 text-white p-5 rounded-xl`}
        >
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="md:col-span-6">
              <div className="lg:ms-8">
                <h2 className="text-orange-600 text-5xl font-bold ">
                  {lang == "en" ? "رسالتنا" : "Our Mission"} <br />{" "}
                  <p
                    className={`m${lang == "ar" ? "l" : "r"}-4 text-orange-600`}
                  >
                    . . .
                  </p>{" "}
                </h2>
                <ul className="list-disc list-inside space-y-2 space-x-2 ml-5 mr-5 mt-3 text-red-700 ">
                  <li className="text-xl">{languages[lang].l1}</li>
                  <li className="text-xl">{languages[lang].l2}</li>
                  <li className="text-xl">{languages[lang].l3}</li>
                  <li className="text-xl">{languages[lang].l4}</li>
                  <li className="text-xl">{languages[lang].l5}</li>
                </ul>
              </div>
            </div>
            <div className="md:col-span-6">
              <div className="lg:me-8">
                <div className="relative">
                  <img
                    src={aboutImage}
                    className="rounded-full shadow-lg dark:shadow-gray-800"
                    alt=""
                  />

                  <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 mx-auto lg:size-72 size-56 flex justify-center items-center bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800">
                    <div className="text-center">
                      <span className="text-indigo-600 text-2xl font-bold mb-0 block">
                        <CountUp className="counter-value" start={1} end={48} />
                        +
                      </span>
                      <span className="font-semibold block mt-2 text-red-600">
                        {lang == "en" ? "سنين" : "years"}
                        <br /> {lang == "en" ? "خبرة" : "experience"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="services"></div>
        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-4xl md:leading-normal text-2xl leading-normal font-semibold">
              {lang == "en" ? "متخصصون في" : "Specialized in"}
            </h3>

            <p className="text-slate-600 text-lg max-w-xl mx-auto">
              {lang == "en"
                ? "تتخصص شركة الرضا في الأعمال الآتية"
                : "Al Reda is specialized in these works"}
            </p>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-[30px] mt-8">
            <div
              className="group rounded shadow-lg relative p-6 overflow-hidden h-[300px] flex justify-center items-center "
              style={{
                backgroundImage: `url(${spec3})`,
                backgroundSize: "cover",
              }}
            >
              <div className="content w-full bg-slate-300 bg-opacity-80 h-[60px] flex justify-center items-center rounded-lg border-2 border-blue-500">
                <a
                  href="https://www.dropbox.com/scl/fi/3ddph3lj3fwd7kn12p6md/.pdf?rlkey=28t6ttzgymipa5lilet822m8b&st=2hcct6np&dl=1"
                  target="_blank"
                  className="title h5 text-2xl font-medium hover:text-indigo-600 text-red-500"
                >
                  {languages[lang].work1}
                </a>
              </div>
            </div>

            <div
              className="group rounded shadow-lg relative p-6 overflow-hidden h-[300px] lg:mt-16 flex justify-center items-center w-full"
              style={{
                backgroundImage: `url(${spec2})`,
                backgroundSize: "cover",
              }}
            >
              <div className="content w-full bg-slate-300 bg-opacity-80 h-[60px] flex justify-center items-center rounded-lg border-2 border-blue-500">
                <Link
                  to={`/projects/marine`}
                  className="title h5 text-2xl font-medium hover:text-indigo-600 text-red-500"
                >
                  {languages[lang].work2}
                </Link>
              </div>
            </div>
            <div
              className="group rounded shadow-lg relative p-6 overflow-hidden h-[300px] flex justify-center items-center w-full"
              style={{
                backgroundImage: `url(${spec1})`,
                backgroundSize: "cover",
              }}
            >
              <div className="content w-full bg-slate-300 bg-opacity-80 h-[60px] flex justify-center items-center rounded-lg border-2 border-blue-500">
                <Link
                  to={`/projects/specialized`}
                  className="title h5 text-2xl font-medium hover:text-indigo-600 text-red-500 text-center"
                >
                  {languages[lang].work3}
                </Link>
              </div>
            </div>

            <div
              className="group rounded shadow-lg relative p-6 overflow-hidden h-[300px] lg:mt-16 flex justify-center items-center w-full"
              style={{
                backgroundImage: `url(${spec4})`,
                backgroundSize: "cover",
              }}
            >
              <div className="content w-full bg-slate-300 bg-opacity-80 h-[60px] flex justify-center items-center rounded-lg border-2 border-blue-500">
                <Link
                  to={`/projects/road`}
                  className={`title h5 font-medium hover:text-indigo-600 text-red-500 text-center ${
                    lang == "ar" ? "text-lg" : "text-xl"
                  }`}
                >
                  {languages[lang].work4}
                  <br />
                  {languages[lang].work5}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <h3 className="mb-4 md:text-4xl md:leading-normal text-2xl leading-normal font-semibold text-center">
            {lang == "en" ? "المكتب التقني" : "The Technial Office"}
          </h3>
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
            <div className="relative order-1 md:order-2">
              <a href="https://www.dropbox.com/scl/fi/4pkuyce7xsbcok0wi3xpo/YACTION-CV-2024.pdf?rlkey=q42zxfsuv1t7y3u3jhlvi83he&st=evao32g2&dl=1" target="_blank">
              <img
                src={
                  "https://res.cloudinary.com/docmgnmpt/image/upload/v1727366692/10_jvw8y9.png"
                }
                className="max-w-[300px] mx-auto"
                alt=""
                />
              </a>
              {/* <div className="overflow-hidden absolute lg:size-[400px] size-[320px] bg-indigo-600/5 bottom-0 end-0 rotate-45 -z-1 rounded-3xl"></div> */}
            </div>

            <div className="lg:me-8 order-2 md:order-1">
              {lang == "en" ? (
                <h4 className="mb-4 text-2xl leading-normal font-medium">
                  المكتب الفنى والاستشاري <br /> مكتب واي أكشن للاستشارات
                  الهندسية
                </h4>
              ) : (
                <h4 className="mb-4 text-2xl leading-normal font-medium">
                  Technical and consulting office <br /> Y Action Engineering
                  Consulting Office
                </h4>
              )}
            </div>
          </div>
        </div>

        <div id="works"></div>
        <div id="contact"></div>
        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center justify-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13162.02433290558!2d33.8075296!3d27.253604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1454cbf0bfa8f26b%3A0x858dcb7b4640ffaf!2z2KfZhNi52K3Zitm!5e0!3m2!1sen!2sin!4v1632390641615!5m2!1sen!2sin"
                title="Google Maps Embed"
                style={{ border: "0" }}
                className="w-full h-[500px]"
                allowFullScreen
              ></iframe>
            </div>

            <div className="lg:col-span-5 md:col-span-6">
              <GetInTuch />
              {/* <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-medium text-center">تواصل معنا</h3>

                                    
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                    <div className="form-icon relative mt-2">
                                                        <Icon.Book className="size-4 absolute top-3 start-4"></ Icon.Book>
                                                        <input name="subject" id="subject" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Subject :" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                    <div className="form-icon relative mt-2">
                                                        <Icon.MessageCircle className="size-4 absolute top-3 start-4"></Icon.MessageCircle>
                                                        <textarea name="comments" id="comments" className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Message :"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" id="submit" name="send" className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center">Send Message</button>
                                    
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
      {/* <CookieModal/> */}
    </div>
  );
}
